<template>
  <div>
      <!-- 
        <script src="./TCaptcha.js"></script> 
        -->
      <!-- 
        <script src="https://ssl.captcha.qq.com/TCaptcha.js"></script>
<script src="https://turing.captcha.qcloud.com/TCaptcha.js"></script>

       -->
  </div>
</template>

<script src="./TCaptcha.js"></script>

<script>
  export default {
    created () {
      this.varify()
    },
    methods: {
      varify () {
        let that=this
        let appid = '2028271277'
        let options = {
          "needFeedBack": false 
        }
        let captcha = new TencentCaptcha(appid, function (res) {
          that.share({ret: 0})
        }, options)
        // 滑块显示
        captcha.show()
      },

      share (data) {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isIOS) {
          window.webkit.messageHandlers.verify.postMessage(data)
        }
        if (isAndroid) {
          window.android.verify(String(data.ret))
        } 
      }
    }
  }
</script>

<style lang="less" scoped>
</style>